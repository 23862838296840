<div
  [class.textbox--error]="errorMessage"
  [class.textbox--large]="size === TextboxSize.Large"
  [class.textbox--password-toggle]="showPasswordToggle"
  [class.textbox--search]="icon"
  [class]="cssClass"
  [style.width]="width"
  class="textbox"
>
  @if (label) {
    <div class="textbox__header">
      @if (label) {
        <div class="textbox__header-label">
          @if (required) {
            <span class="textbox__header-label--required">*</span>
          }
          {{ label }}
        </div>
      }
      @if (description) {
        <div class="textbox__header-description">{{ description }}</div>
      }
    </div>
  }

  <div class="textbox__input">
    @if (icon) {
      <i
        class="textbox__input-icon"
        [class]="icon"
      >
      </i>
    }

    @if (type !== TextboxType.Textarea) {
      <input
        #nativeInput
        [type]="_type"
        [value]="value"
        (input)="onChange($event.target['value'])"
        (blur)="onTouched()"
        [disabled]="disabled"
        [placeholder]="placeholder"
        class="textbox__input-inner"
      />
    }

    @if (type === TextboxType.Textarea) {
      <textarea
        #nativeTextarea
        [value]="value"
        (input)="onChange($event.target['value'])"
        (blur)="onTouched()"
        [disabled]="disabled"
        [placeholder]="placeholder"
        class="textbox__input-inner"
      ></textarea>
    }

    @if (showPasswordToggle) {
      <div
        class="textbox__input-password-toggle"
        (mousedown)="showPasswordPreview()"
        (mouseup)="hidePasswordPreview()"
      >
        @if (!previewPassword) {
          <i class="icon-hide"></i>
        }
        @if (previewPassword) {
          <i class="icon-show"></i>
        }
      </div>
    }

    @if (clearIconVisible) {
      <i
        class="textbox__input-clear-icon icon-clear"
        (click)="clearIconClick.emit()"
      >
      </i>
    }

    @if (type === TextboxType.Textarea) {
      <i class="icon-handle textbox__input-textarea-handle"></i>
    }
  </div>

  @if (errorMessage) {
    <div class="textbox__footer">
      @if (errorMessage) {
        <div class="textbox__footer-error">
          <i class="icon-error-fill textbox__footer-error-icon"></i>
          <div class="textbox__footer-error-message">
            {{ errorMessage }}
          </div>
        </div>
      }
    </div>
  }
</div>
